.wrap {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.select {
  width: 96px !important;
}

.singleTimeArea {
  width: 210px !important;
}

@defaultTheme: true; @import "~@aloudata/aloudata-design/dist/style/themes/default/index.less";@defaultMixin: true; @import "~@/styles/mixins/index.less";