.menu {
  height: 200px;
}

.select {
  width: 160px !important;
}

.overlay {
  width: 240px;
  height: 260px;
  z-index: 100;
}

.dropdownWrap {
  .searchWrap {
    height: 32px;
    border-bottom: 1px solid #d1d5db;
    padding-bottom: 1px;
  }

  .container {
    height: 222px;
    padding: 0 8px;

    .treeWrap {
      width: 100%;
      overflow-x: hidden;
    }

    .columnName {
      font-size: 12px;
    }

    .granularityWrap {
      flex-grow: 1;
      display: flex;
      align-items: center;

      .granularityText {
        font-size: 12px;
        margin-left: 4px;
      }
    }
  }
}

.title {
  min-width: 100px;
  display: flex;
  align-items: flex-start;
  gap: var(--alias-spacing-75, 6px);
  flex: 1 0 0;

  .icon {
    flex-shrink: 0;
    height: 20px;
    display: flex;
    align-items: center;
  }

  .content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-width: 0;
    gap: 4;

    .text {
      overflow: hidden;
      color: var(--alias-colors-text-default, #1f2937);
      text-overflow: ellipsis;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }

    .description {
      overflow: hidden;
      color: var(--alias-colors-text-subtlest, #9ca3af);
      text-overflow: ellipsis;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
  }
}

@defaultTheme: true; @import "~@aloudata/aloudata-design/dist/style/themes/default/index.less";@defaultMixin: true; @import "~@/styles/mixins/index.less";