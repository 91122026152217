.previewCard {
  color: #374151;
  background: var(--alias-colors-bg-skeleton-stronger, #e5e7eb);
  font-size: 12px;
  padding: 1px 4px;
  line-height: 16px;
  border-radius: var(--alias-radius-25, 2px);
  border: var(--alias-stroke-md, 1px) solid
    var(--alias-colors-border-transp, rgba(0, 0, 0, 0));
}
