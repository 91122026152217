.wrap {
  display: flex;
  column-gap: 12px;
  align-items: flex-start;
}

.singleArea {
  width: 210px;
}

@defaultTheme: true; @import "~@aloudata/aloudata-design/dist/style/themes/default/index.less";@defaultMixin: true; @import "~@/styles/mixins/index.less";