.dropdown {
  padding: 0 !important;
  z-index: 1051;
  overflow: hidden;
}

.listItemPopover {
  padding: 0;
  width: 240px;
  overflow: hidden;

  .listItemPopoverTitle {
    padding: 12px;
    display: flex;
    flex-direction: column;

    .listItemPopoverTitleDisplayName {
      display: flex;
      align-items: center;
      gap: var(--alias-spacing-75, 6px);

      .listItemPopoverTitleIcon {
        flex-shrink: 0;
        align-items: center;
        height: 20px;
        width: 20px;
      }

      .listItemPopoverTitleDisplayNameText {
        min-width: none;
        flex-grow: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: var(--alias-colors-text-default, #1f2937);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
    }

    .listItemPopoverTitleName {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-top: 4px;
      color: var(--alias-colors-text-subtlest, #9ca3af);
    }

    .listItemPopoverTitleDesc {
      margin-top: 12px;
      word-wrap: break-word;
      white-space: wrap;
      color: var(--alias-colors-text-default, #1f2937);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
  }

  .listItemPopoverContent {
    display: flex;
    padding: 8px;
    align-items: center;
    font-size: 12px;
    gap: var(--alias-spacing-50, 4px);
    align-self: stretch;
    border-top: var(--alias-stroke-md, 1px) solid
      var(--alias-colors-border-default, #e5e7eb);
    background: var(--alias-colors-bg-skeleton-subtle, #f9fafb);
    border-radius: var(--alias-radius-0, 0) var(--alias-radius-0, 0)
      var(--alias-radius-75, 6px) var(--alias-radius-75, 6px);

    .listItemPopoverContentPath {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      min-width: 0;
      color: var(--alias-colors-text-subtle, #4b5563);
    }
  }
}

@defaultTheme: true; @import "~@aloudata/aloudata-design/dist/style/themes/default/index.less";@defaultMixin: true; @import "~@/styles/mixins/index.less";