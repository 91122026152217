.content {
  display: block;
}

.item {
  display: flex;

  &.itemMargin {
    margin-top: 36px;
    margin-bottom: 12px;
  }

  .label {
    font-weight: 500;
    color: #4b5563;
    text-align: right;
    margin-right: 12px;
  }

  .content {
    font-weight: 400;
    color: #1f2937;
  }

  .tablePicker {
    width: 340px;
    margin-bottom: 0;
  }

  .columnSelect {
    width: 340px;
    margin-left: 8px;
    margin-bottom: 0;
  }
}

.filter {
  margin-bottom: 0 !important;
}

@defaultTheme: true; @import "~@aloudata/aloudata-design/dist/style/themes/default/index.less";@defaultMixin: true; @import "~@/styles/mixins/index.less";