.columnList {
  display: flex;
  flex-direction: column;
  width: 100%;

  .title {
    display: flex;
    padding: 8px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    justify-content: space-between;
    border-bottom: var(--alias-stroke-md, 1px) solid
      var(--alias-colors-border-default, #e5e7eb);
    background: #f9fafb;

    .close {
      cursor: pointer;
    }
  }

  .search {
    padding: 8px;
    flex: 0 0 auto;
  }
}

.dropdown {
  padding: 0 !important;
  z-index: 1052;
  overflow: hidden;
}

@defaultTheme: true; @import "~@aloudata/aloudata-design/dist/style/themes/default/index.less";@defaultMixin: true; @import "~@/styles/mixins/index.less";