.wrap {
  :global {
    .ant-select-selection-item {
      display: flex;
      align-items: center;
    }

    .ant-select-selector {
      padding-left: 6px !important;
    }

    .ant-select-selection-placeholder {
      padding-left: 4px !important;
    }
  }
}

@defaultTheme: true; @import "~@aloudata/aloudata-design/dist/style/themes/default/index.less";@defaultMixin: true; @import "~@/styles/mixins/index.less";