@import (reference) '../mixins.less';

.wrap {
  display: flex;
  align-items: center;
  column-gap: 4px;

  .label {
    color: var(--alias-colors-text-subtle, #4b5563);
    font-size: 12px;
    font-weight: 500;
  }

  .preview {
    .previewCard();
  }
}

@defaultTheme: true; @import "~@aloudata/aloudata-design/dist/style/themes/default/index.less";@defaultMixin: true; @import "~@/styles/mixins/index.less";