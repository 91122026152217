.wrap {
  display: flex;
  align-items: center;
  column-gap: 6px;
}

.operator {
  width: 100px;
}


@defaultTheme: true; @import "~@aloudata/aloudata-design/dist/style/themes/default/index.less";@defaultMixin: true; @import "~@/styles/mixins/index.less";