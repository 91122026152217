.wrap {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: 100%;
}

.main {
  width: 100%;
}

.error {
  font-size: 12px;
  line-height: 16px;
  color: #b91c1c;
  width: 100%;
}

.singleTimeArea {
  width: 210px;
}

@defaultTheme: true; @import "~@aloudata/aloudata-design/dist/style/themes/default/index.less";@defaultMixin: true; @import "~@/styles/mixins/index.less";