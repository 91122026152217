.dynamicDate {
  display: flex;
  width: 100%;
  justify-content: center;
  border-top: 1px solid rgba(5, 5, 5, 0.06);
  padding-top: 16px;

  .left {
    line-height: 32px;
    margin-right: 8px;
  }

  .right {
    display: flex;
    flex-direction: column;

    .bottom {
      margin-top: 16px;
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .errorInput {
      border-color: var(--alias-colors-border-danger, #dc2626) !important;
    }

    .error {
      color: var(--alias-colors-text-danger, #b91c1c);
      margin-top: 8px;
    }
  }
}

@defaultTheme: true; @import "~@aloudata/aloudata-design/dist/style/themes/default/index.less";@defaultMixin: true; @import "~@/styles/mixins/index.less";