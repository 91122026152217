.wrap {
  width: 100%;
  position: relative;
}

.inputWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  :global {
    .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
      border-color: #3271c9 !important;
    }
  }
}

.inputSearch.inputSearch {
  border-width: 0;
  border-radius: 0 !important;
  box-shadow: 0 0 0 0 rgba(5, 145, 255, 0.1) !important;
  border-bottom: 1px solid #dbdbdb !important;

  &:focus {
    border-width: 0 !important;
  }

  &:hover {
    border-bottom: 1px solid #dbdbdb !important;
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
  }
}

.loadingBar {
  position: absolute;
  top: 0; // dropdown padding-top 的高度
  width: 100%;
  z-index: 1;
}

.dropdown {
  padding: 0 !important;
}

@defaultTheme: true; @import "~@aloudata/aloudata-design/dist/style/themes/default/index.less";@defaultMixin: true; @import "~@/styles/mixins/index.less";