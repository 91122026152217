.modalWrapper {
  display: flex;
  align-items: center;
  @media screen and (max-height: 640px) {
    align-items: flex-start;
  }

  .modalContent[class] {
    top: unset;

    :global {
      .ant-modal-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        .ant-modal-body {
          flex-grow: 1;
          min-height: none;
          max-height: none;
        }
      }
    }
  }
}

.layout {
  display: flex;
  position: relative;
  width: 100%;
  height: var(--modal-body-height-in-large-modal);

  .left {
    flex: 0 0 360px;
    height: 100%;
  }

  .middle {
    padding: 24px;
    flex: 0 0 var(--middle-width-in-large-modal, 240px);
    height: 100%;
  }

  .right {
    flex: 1;
    padding: 24px;
    height: 100%;
  }

  &::after {
    content: '';
    position: absolute;
    left: 359px;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: var(--alias-colors-border-default, #e5e7eb);
  }

  &.hasMiddle::before {
    content: '';
    position: absolute;
    left: calc(360px + var(--middle-width-in-large-modal, 240px));
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: var(--alias-colors-border-default, #e5e7eb);
  }

  .scrollInner {
    height: 100%;

    // 这里没有更好的办法选到改div
    /* stylelint-disable-next-line selector-max-type */
    & > div {
      height: 100%;
    }
  }
}

@defaultTheme: true; @import "~@aloudata/aloudata-design/dist/style/themes/default/index.less";@defaultMixin: true; @import "~@/styles/mixins/index.less";