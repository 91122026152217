.wrapper {
  width: 100%;
  padding-bottom: 4px;
  background-color: @BG100;
  color: black;
  overflow-y: hidden !important;
  padding-top: 0 !important;

  .title {
    display: flex;
    padding: 14px 16px;
    color: var(--alias-colors-text-default, #1f2937);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  .searchWrap {
    padding: 0 8px 8px;
  }
}

.contentWrapper {
  border-top: 1px solid var(--alias-colors-border-strong, #d1d5db);
  padding: 0 8px;

  .list {
    padding: 8px 0;
  }

  .listItem {
    border-radius: var(--alias-radius-75, 6px);
    cursor: pointer;

    &:hover {
      background: var(--alias-colors-bg-interaction-hover, #f9fafb);
    }

    &.selected {
      background: var(--alias-colors-bg-selected-default, #e8f2fe);
    }
  }
}

.disabled {
  opacity: 0.4;
}

.border {
  border: 1px solid @BG60;
  box-shadow: @shadow-L;
  max-height: 500px;
  overflow-y: auto;
  border-radius: 8px;
}

.padding {
  padding: 8px;
}

.dropdown {
  z-index: 1051;
}

@defaultTheme: true; @import "~@aloudata/aloudata-design/dist/style/themes/default/index.less";@defaultMixin: true; @import "~@/styles/mixins/index.less";