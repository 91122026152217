.select {
  width: 100% !important;
}

.dropdownContent {
  width: 100%;
  height: 360px;
  padding: 0 6px;
}

@defaultTheme: true; @import "~@aloudata/aloudata-design/dist/style/themes/default/index.less";@defaultMixin: true; @import "~@/styles/mixins/index.less";