@content-padding: 24px;

.content {
  padding: 0 var(--alias-padding-400, 32px) var(--alias-padding-0, 0)
    var(--alias-padding-400, 32px);
  position: relative;
  background: var(--alias-colors-bg-skeleton-subtler, #fff);
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  .headerArea {
    flex: 0 0 auto;
    padding-top: var(--alias-padding-300, 24px);
    padding-bottom: 16px;
    background: var(--alias-colors-bg-skeleton-subtler, #fff);
    height: 64px;

    .header {
      height: 32px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;

      .title {
        height: 100%;
        display: flex;
        align-items: center;
        color: var(--alias-colors-text-strong, #111827);
        font-weight: 600;
        line-height: 28px;
        font-size: 20px;
      }

      .handles {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: var(--alias-spacing-100, 8px);
      }
    }
  }

  .bodyArea {
    flex: 1 1 auto;
    overflow: hidden;
  }

  .tabContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
  }

  .condition {
    display: flex;
    flex: 0 0 auto;
    overflow: hidden;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    z-index: 10;
    padding-bottom: 16px;

    .total {
      height: 100%;
      display: flex;
      align-items: center;
      color: var(--alias-colors-text-strong, #111827);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .tableArea {
    flex: 1 1 auto;
    overflow: hidden;
    padding-bottom: var(--alias-padding-300, 24px);

    :global {
      .ald-table-main {
        color: #1f2937;
      }

      .ald-table-th {
        .ald-table-align-right {
          justify-content: left !important;
        }
      }
    }
  }
}

@defaultTheme: true; @import "~@aloudata/aloudata-design/dist/style/themes/default/index.less";@defaultMixin: true; @import "~@/styles/mixins/index.less";