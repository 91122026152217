.wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.isResizing {
  pointer-events: none;
}

.header {
  flex: 0 0 auto;
  padding: 8px 8px 14px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title {
    flex: 1 1 auto;
  }

  .btns {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.content {
  flex: 1 1 auto;
  overflow: hidden;
}

.footer {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 8px 12px;
  border-top: 1px solid #e5e7eb;
}

@defaultTheme: true; @import "~@aloudata/aloudata-design/dist/style/themes/default/index.less";@defaultMixin: true; @import "~@/styles/mixins/index.less";