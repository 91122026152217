@import '@/styles/mixins/index.less';

.wrap {
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: 12px;
}

.loadingArea {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.datasourceTitle {
  width: 100%;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  align-items: center;

  .left {
    display: flex;
    justify-content: start;
    flex: 1 1 auto;
    overflow: hidden;
  }

  .right {
    display: none;
    flex: 0 0 auto;
  }

  &:hover {
    .right {
      display: inline-flex;
      align-items: center;
    }
  }

  .name {
    overflow: hidden;
    display: inline-flex;
    align-items: center;

    .ellipsis();
  }

  .external {
    flex: 0 0 auto;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    padding: 2px 6px;
    border-radius: 99px;
    color: #9ca3af;
  }
}

.treeNode {
  width: 100%;
  overflow: hidden;

  :global {
    .ald-tree-node-content-wrapper {
      overflow: hidden;
    }

    .ald-tree-title {
      overflow: hidden;
    }
  }
}


.treeContainer {
  height: 100%;
}

.searchContainer {
  display: flex;
  height: 100%;
  flex-direction: column;

  .header {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    padding-bottom: 12px;
    cursor: pointer;
    column-gap: 6px;
    overflow: hidden;
    width: 100%;

    .icon {
      flex: 0 0 auto;
      display: flex;
      align-items: center;
    }

    .catalogTitle {
      .ellipsis();
    }
  }

  .input {
    flex: 0 0 auto;
    padding-bottom: 12px;
  }

  .body {
    flex: 1 1 auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .loadingArea {
    width: 100%;
    height: 100%;
  }
}

.listItemWrap {
  position: relative;
  width: 100%;

  .columnListBtn {
    position: absolute;
    top: 0;
    right: 8px;
    bottom: 0;
    margin: auto;
    visibility: hidden;
    background-color: #f9fafb;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;

    &.btnSelected {
      visibility: visible;
    }
  }

  &:hover {
    .columnListBtn {
      visibility: visible;
    }
  }
}

.listItem {
  &:hover {
    background-color: #f9fafb;
  }

  &.selected {
    background: #f9fafb;
  }
}

.datasourceItem {
  cursor: pointer;

  &:hover {
    border-radius: var(--alias-radius-75, 6px);
    background: var(--alias-colors-bg-selected-default, #e8f2fe);
  }

  .arrow {
    display: flex;
    align-items: center;
  }

  .fileCatalogLogo {
    color: #fff;
  }
  
}

@defaultTheme: true; @import "~@aloudata/aloudata-design/dist/style/themes/default/index.less";@defaultMixin: true; @import "~@/styles/mixins/index.less";