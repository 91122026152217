@import (reference) '../mixins.less';

.wrap {
  display: flex;
  row-gap: 8px;
  flex-direction: column;
}

.config {
  display: flex;
  align-items: center;
  column-gap: 6px;
}

.preview {
  .previewCard();
}

@defaultTheme: true; @import "~@aloudata/aloudata-design/dist/style/themes/default/index.less";@defaultMixin: true; @import "~@/styles/mixins/index.less";