.wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 4px;
}

.hasIcon {
  padding: 0 4px;
}

.icon {
  display: flex;
  align-items: center;
}

.nameWrapper {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 16px;
}

.hasBorder {
  display: flex;
  padding: var(--alias-padding-25, 2px) var(--alias-padding-75, 6px);
  justify-content: center;
  align-items: center;
  gap: var(--alias-spacing-50, 4px);
  border-radius: var(--alias-radius-50, 4px);
  border: 1px solid
    var(--alias-colors-border-accent-gray-subtler-default, #e5e7eb);
  background: var(--alias-colors-bg-accent-gray-subtler-default, #f9fafb);
}

@defaultTheme: true; @import "~@aloudata/aloudata-design/dist/style/themes/default/index.less";@defaultMixin: true; @import "~@/styles/mixins/index.less";