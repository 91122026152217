.wrap {
  padding: 16px;
  display: flex;
  flex-direction: column;
}

.item {
  padding: 6px;
  height: 32px;
  display: flex;
  align-items: center;

  &.active {
    background: var(--alias-colors-bg-selected-default, #e8f2fe);
    border-radius: 6px;
  }

  &:hover {
    cursor: pointer;
  }
}

@defaultTheme: true; @import "~@aloudata/aloudata-design/dist/style/themes/default/index.less";@defaultMixin: true; @import "~@/styles/mixins/index.less";