.metricCard {
  width: 288px;
  height: 180px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.header {
  flex: 0 0 auto;
  padding: 8px 12px 12px;
  width: 100%;
  border-bottom: 1px solid var(--alias-colors-border-default, #e5e7eb);
  display: flex;
  justify-content: space-between;

  .nameArea {
    display: flex;
    gap: 4px;
    overflow: hidden;
    flex: 1 1 auto;

    .dataType {
      flex: 0 0 auto;
    }

    .displayName {
      color: var(--alias-colors-text-default, #1f2937);
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      .ellipsis();
    }
  }

  .icon {
    flex: 0 0 auto;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
}

.body {
  flex: 1 1 auto;
  overflow: hidden;
  overflow-y: auto;
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

@defaultTheme: true; @import "~@aloudata/aloudata-design/dist/style/themes/default/index.less";@defaultMixin: true; @import "~@/styles/mixins/index.less";