.wrap {
  display: flex;
  align-items: flex-start;
  height: 284px;
  width: 100%;
}

.sidebar {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  border-right: var(--alias-stroke-md, 1px) solid
    var(--alias-colors-border-default, #e5e7eb);
}

.content {
  flex: 1;
  padding: 16px 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .reservation {
    display: flex;
    flex: 1;
    align-items: flex-end;
  }
}

.header {
  display: flex;
  column-gap: 12px;
  align-items: center;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: var(--alias-stroke-md, 1px) solid
    var(--alias-colors-border-subtle, #f3f4f6);
}

@defaultTheme: true; @import "~@aloudata/aloudata-design/dist/style/themes/default/index.less";@defaultMixin: true; @import "~@/styles/mixins/index.less";