@modelHeight: 88vh;
@modelHeaderHeight: 44px;
@modelFooterHeight: 44px;

.ellipsis() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.aldIconStyle(@color,@fill:'') {
  :global {
    .ald-icon {
      color: @color;
      fill: @fill;
    }
  }
}
