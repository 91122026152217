.select {
  width: 240px !important;
}

.menuWrapper {
  max-height: 400px !important;
  overflow: hidden;
  overflow-y: auto;
}

@defaultTheme: true; @import "~@aloudata/aloudata-design/dist/style/themes/default/index.less";@defaultMixin: true; @import "~@/styles/mixins/index.less";