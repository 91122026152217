.search {
  flex: 0 0 auto;
  padding: 0 8px 8px;
  border-bottom: 1px solid #e5e7eb;
}

.hideUnAvailable {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 8px 0;

  .tip {
    color: #9ca3af;
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 12px;
    padding-left: 2px;
  }

  .switch {
    display: flex;
    align-items: center;
  }
}

.treeWrapper {
  flex: 1 1 auto;
  padding: 8px;
  overflow: hidden;
}

.metricTreePicker {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &.noPadding {
    .search {
      padding-left: 0;
      padding-right: 0;
    }

    .hideUnAvailable {
      padding-left: 0;
      padding-right: 0;
    }

    .treeWrapper {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.empty {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 4px;

  .title {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: var(--alias-colors-text-default, #1f2937);
  }

  .desc {
    color: var(--alias-colors-text-subtle, #4b5563);
    text-align: center;
    font-size: 12px;
    line-height: 16px;
  }
}

.tree {
  overflow: hidden;
  height: 100%;
  width: 100%;

  .metricItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    height: 20px;
    font-size: 14px;
    width: 100%;
    cursor: pointer;

    &.small {
      height: 16px;
      font-size: 12px;
    }

    &.disabled {
      cursor: default;
      opacity: 0.5;

      &:hover {
        cursor: default;
        background-color: transparent;
      }
    }

    .dataType {
      flex: 0 0;
      display: flex;
      align-items: center;
    }

    .nameWrapper {
      flex: 1 1 auto;
      width: 0;

      .name {
        .ellipsis();
      }
    }
  }
}

@defaultTheme: true; @import "~@aloudata/aloudata-design/dist/style/themes/default/index.less";@defaultMixin: true; @import "~@/styles/mixins/index.less";