@import (reference) '../mixins.less';

.wrap {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.header {
  display: flex;
  column-gap: 4px;
  align-items: center;

  .previewLabel {
    font-size: 12px;
    font-weight: 500;
    color: var(--alias-colors-text-subtle, #4b5563);
  }

  .preview {
    .previewCard();
  }
}

.content {
  .dynamicTime {
    display: flex;
    align-items: center;
    column-gap: 6px;
  }

  .t {
    flex: 0 0 auto;
  }

  .operator {
    width: 58px !important;
  }

  .offset {
    flex: 1 0.5 auto;
  }

  .unit {
    width: 88px !important;
  }
}

.w100 {
  width: 100% !important;
}

.operatorIcon {
  display: flex;
  align-items: center;
  height: 100%;
}

@defaultTheme: true; @import "~@aloudata/aloudata-design/dist/style/themes/default/index.less";@defaultMixin: true; @import "~@/styles/mixins/index.less";