.wrap {
  width: 100%;

  .customCalendarPickerWrap {
    display: flex;
    align-items: center;
    gap: 8px;

    .specificCustomCalendarPicker {
      width: 132px;
    }
  }

  .extractWrap {
    display: flex;
    align-items: center;
    gap: 8px;

    .extractKeyInput {
      width: 132px;
    }

    .extractValueInput {
      width: 132px;
    }
  }
}

@defaultTheme: true; @import "~@aloudata/aloudata-design/dist/style/themes/default/index.less";@defaultMixin: true; @import "~@/styles/mixins/index.less";