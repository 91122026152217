.metricItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  height: 20px;
  font-size: 14px;
  width: 100%;
  cursor: pointer;

  &.small {
    height: 16px;
    font-size: 12px;
  }

  &.disabled {
    cursor: default;
    opacity: 0.5;

    &:hover {
      cursor: default;
      background-color: transparent;
    }
  }

  .dataType {
    flex: 0 0;
    display: flex;
    align-items: center;
  }

  .nameWrapper {
    flex: 1 1 auto;
    width: 0;

    .name {
      .ellipsis();
    }
  }
}

@defaultTheme: true; @import "~@aloudata/aloudata-design/dist/style/themes/default/index.less";@defaultMixin: true; @import "~@/styles/mixins/index.less";