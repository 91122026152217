.wrap {
  display: flex;
  column-gap: 6px;
  align-items: center;
  width: 100%;
}

.autoWidth {
  flex: 1;
}

.valueList {
  flex: 1 1 auto;
}

@defaultTheme: true; @import "~@aloudata/aloudata-design/dist/style/themes/default/index.less";@defaultMixin: true; @import "~@/styles/mixins/index.less";