.form {
  height: 100%;
  display: flex;

  .title {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 8px;
  }

  .card {
    width: 100%;
    border: 1px solid #e5e7eb;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.05), 0 1px 2px 0 rgba(0, 0, 0, 0.02);

    :global {
      .ant-card-body {
        display: flex;
      }
    }

    .cardLeft {
      width: 68px;
      font-weight: 500;
      font-size: 14px;
    }

    .cardRight {
      padding-top: 2px;
      flex: 1;

      :global {
        .ant-form-item {
          margin-bottom: 0 !important;
        }
      }

      .innerCard {
        margin-top: 12px;

        :global {
          .ant-card-body {
            padding: 0;
            width: 100%;

            .ant-form-item {
              margin-bottom: 0;
              width: 100%;
            }
          }
        }

        padding: 16px;

        .selectUser {
          width: 100%;

          .selectUserType {
            display: flex;
            margin-bottom: 22px;

            :global {
              .ant-form-item {
                width: auto;
              }
            }

            .title {
              font-size: 14px;
              font-weight: 500;
              margin-right: 12px;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  .left {
    width: 360px;
    padding: 24px;
    border-right: 1px solid #e5e7eb;
    height: 100%;
  }

  .right {
    padding: 24px;
    flex: 1;
  }
}

@defaultTheme: true; @import "~@aloudata/aloudata-design/dist/style/themes/default/index.less";@defaultMixin: true; @import "~@/styles/mixins/index.less";