.inputSearch :global {
  .ald-input.ald-input.ant-input-affix-wrapper {
    width: var(--init-width) !important;
    background-color: var(--alias-colors-bg-skeleton-subtle, #f9fafb);
    cursor: pointer;

    input {
      background-color: var(--alias-colors-bg-skeleton-subtle, #f9fafb);
    }

    .ant-input-suffix {
      display: none;
    }

    ::placeholder {
      color: var(--alias-colors-text-default, #1f2937);
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      background-color: var(--alias-colors-bg-skeleton-subtle, #f9fafb);
      line-height: 16px; /* 133.333% */
    }

    &.ant-input-affix-wrapper-focused,
    &:hover,
    &.inputSearchHasValue {
      width: 200px !important;
      transition: all 0.2s ease 0.1s !important;
      background-color: var(--alias-colors-bg-skeleton-subtler, #fff);

      &.inputMode {
        width: 100% !important;
      }

      input {
        background-color: #fff;
      }

      ::placeholder {
        color: var(--alias-colors-text-subtlest, #9ca3af);
        background-color: var(--alias-colors-bg-skeleton-subtler, #fff);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
        transition: all 0.2s ease 0.1s !important;
      }
    }

    &.ant-input-affix-wrapper-focused,
    &.inputSearchHasValue {
      .ant-input-suffix {
        display: inline-block;

        .ant-input-clear-icon {
          font-size: 16px !important;
        }
      }
    }
  }
}

@defaultTheme: true; @import "~@aloudata/aloudata-design/dist/style/themes/default/index.less";@defaultMixin: true; @import "~@/styles/mixins/index.less";