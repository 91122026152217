.icon {
  cursor: pointer;
}

.operation {
  width: 105px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.modalInner {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  height: 100%;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    height: 32px;
    color: var(--alias-colors-text-subtle, #4b5563);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px; /* 142.857% */

    .input {
      width: 350px !important ;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--alias-spacing-50, 4px);

    .contentTitle {
      color: var(--alias-colors-text-subtle, #4b5563);
      font-size: 14px;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
    }

    .contentDesc {
      color: var(--alias-colors-text-subtle, #4b5563);
      font-size: 12px;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
    }

    .table {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 12px;

      :global {
        .ald-table-th:not(:nth-last-child(2)),
        .ald-table-td:not(:last-of-type) {
          border-right: 1px solid var(--alias-colors-border-default, #e5e7eb) !important;
        }
      }

      .errorList {
        display: flex;
        padding: var(--alias-padding-75, 6px) var(--alias-padding-100, 8px);
        align-items: center;
        gap: var(--alias-spacing-100, 8px);
        align-self: stretch;
        border-radius: var(--alias-radius-50, 4px);
        background: var(--alias-colors-bg-accent-red-subtler-default, #fee2e2);
        color: var(--alias-colors-text-default, #1f2937);
        font-size: 14px;
        line-height: 20px; /* 142.857% */
      }
    }
  }
}

.inputWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.hasError {
  margin: -16px;
  border: 1.5px solid var(--alias-colors-border-danger, #dc2626);
  padding-right: 10px;
  height: 47px;

  .select {
    width: 102px !important;
  }

  .userSelect {
    width: 234px !important;
  }
}

@defaultTheme: true; @import "~@aloudata/aloudata-design/dist/style/themes/default/index.less";@defaultMixin: true; @import "~@/styles/mixins/index.less";