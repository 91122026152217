.wrap {
  width: 100%;
  border: 1px solid #e5e7eb;
  border-radius: 6px;

  .desc {
    margin: 16px 0 0 16px;
  }
}

@defaultTheme: true; @import "~@aloudata/aloudata-design/dist/style/themes/default/index.less";@defaultMixin: true; @import "~@/styles/mixins/index.less";