.textAreaContainer {
  margin-top: 8px;

  .textArea {
    height: 288px !important;
    resize: none !important;
  }
}

.info {
  border-radius: 2px;
  background: #f0f6ff;
  display: flex;
  padding: 8px;
  gap: 4px;
  line-height: 16px;
}

.introduce {
  margin-top: 4px;
  color: #858585;
  font-size: 12px;
  line-height: 16px;
}

@defaultTheme: true; @import "~@aloudata/aloudata-design/dist/style/themes/default/index.less";@defaultMixin: true; @import "~@/styles/mixins/index.less";