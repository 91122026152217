.wrap {
  display: flex;
  gap: 6px;

  .select {
    width: 195px !important;
  }

  .operator {
    width: 144px;
  }
}

@defaultTheme: true; @import "~@aloudata/aloudata-design/dist/style/themes/default/index.less";@defaultMixin: true; @import "~@/styles/mixins/index.less";