.wrap {
  display: flex;
  gap: 6px;

  .select {
    width: 96px !important;
  }

  .input {
    width: 96px !important;
  }
}

@defaultTheme: true; @import "~@aloudata/aloudata-design/dist/style/themes/default/index.less";@defaultMixin: true; @import "~@/styles/mixins/index.less";