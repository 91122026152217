.wrap {
  position: relative;
  // min-width: 200px;
  display: inline-block;
}

.nullLabel {
  color: #98a2b3 !important;
  font-style: italic;
}

.selectedValue {
  font-size: 12px;
  margin-right: 4px;
  display: inline-block;
  max-width: 255px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  top: 3px;
}

.footer {
  width: 100%;
  height: 32px;
  padding: 8px 12px;
  border-top: 1px solid #dbdbdb;
  display: flex;
  justify-content: space-between;
  color: #3769ad;
  font-size: 12px;
  line-height: 16px;
}

.showOptionsValue {
  display: none;
  align-items: center;
  text-align: center;
  cursor: pointer;
}

.multiInputBtn {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.isShowSelectValue {
  display: flex;
}

.isShowAllValue {
  display: flex;
}

.selectedValueDisabled {
  opacity: 0.5;
  cursor: not-allowed;
}

// .drop {
//   // width: 280px !important;
// }

.checkAll {
  padding-left: 18px;
}

.inputWrapper {
  display: none;
  flex-direction: row;
  align-items: center;

  :global {
    .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
      border-color: #3271c9 !important;
    }
  }
}

.inputSearch.inputSearch {
  border-width: 0;
  border-radius: 0 !important;
  box-shadow: 0 0 0 0 rgba(5, 145, 255, 0.1) !important;
  border-bottom: 1px solid #dbdbdb;

  &:focus {
    border-width: 0 !important;
  }

  &:hover {
    border-bottom: 1px solid #dbdbdb !important;
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
  }
}

.showSearchInput {
  display: flex;
}

.contentWrapper {
  overflow: auto;

  :global {
    .ant-select-item-option-content {
      white-space: pre;
    }
  }
}

.selectLen {
  display: none;
  border-radius: 2px;
  width: auto;
  height: auto;
}

.showSelectLen {
  display: inline-block;
  cursor: pointer;
  line-height: 16px;
  align-items: center;
  padding: 0 4px;
  background: #f1f1f1;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  color: #171717;
}

.small {
  display: flex;
  justify-content: center;
  align-items: center;
}

.addBtn {
  display: none;
  flex-direction: row;
  align-items: center;
  padding: 6px 12px;
  gap: 4px;
  font-size: 12px;
  line-height: 16px;
  color: #171717;
  cursor: pointer;

  .searchValue {
    white-space: pre !important; // 不加要被覆盖
    display: inline-block;
    max-width: 200px;
    .ellipsis();
  }
}

.showAddBtn {
  display: flex;
}

.empty {
  height: 200px;
}

.spin {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkboxWrapper {
  padding: 6px 12px 6px 14px;
  display: flex;
  align-items: center;
  column-gap: 6px;
  cursor: pointer;
  position: relative;

  .checkboxLabel {
    position: absolute;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .allLabel {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.dropdownRenderWrapper {
  position: relative;

  .loadingBar {
    position: absolute;
    top: -4px; // dropdown padding-top 的高度
    width: 100%;
    z-index: 1;
  }
}

.optionsTip {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 12px;
  gap: 4px;
  height: 28px;
  font-size: 12px;
  line-height: 16px;
  color: #858585;
}

.select {
  width: 274px !important;
  background: #fff;
}

@defaultTheme: true; @import "~@aloudata/aloudata-design/dist/style/themes/default/index.less";@defaultMixin: true; @import "~@/styles/mixins/index.less";