.item {
  font-size: 12px;
  line-height: 16px;
  word-break: break-all;

  .label {
    color: var(--alias-colors-text-subtlest, #9ca3af);
    padding-right: 4px;
  }

  .value {
    color: var(--alias-colors-text-subtle, #4b5563);
  }

  &.vertical {
    flex-direction: column;

    .label {
      padding-bottom: 4px;
      display: block;
    }

    .value {
      display: block;
    }
  }

  &.flex {
    display: flex;

    .label {
      flex: 0 0 auto;
    }

    .value {
      flex: 1 1 auto;
      overflow: hidden;
    }
  }
}

@defaultTheme: true; @import "~@aloudata/aloudata-design/dist/style/themes/default/index.less";@defaultMixin: true; @import "~@/styles/mixins/index.less";