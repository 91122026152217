.deleteIcon {
  cursor: pointer;
}

.input {
  padding-left: 0 !important;
}

.wrap {
  :global {
    .ald-table-body {
      min-height: 0 !important;
    }
  }
}

@defaultTheme: true; @import "~@aloudata/aloudata-design/dist/style/themes/default/index.less";@defaultMixin: true; @import "~@/styles/mixins/index.less";